import React, { useEffect, useState } from 'react';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import axios from 'axios';
import ScreenConnected from './ScreenConnected';
import io from 'socket.io-client';
import './QRCode.css';
import trillBoardLogo from '../Trillboard Logo.png';
import videoImage from '../Group 1686555205.png';
import { QRCode } from 'react-qrcode-logo';

const QRcode = () => {
    const [myId, setmyId] = useState('');
    const [connected, setconnected] = useState(false);
    const [myFingerPrint, setmyFingerPrint] = useState('');
    const [loader, setloader] = useState(false);
    const [locationAllowed, setlocationAllowed] = useState(null);
    const [lat, setlat] = useState(null);
    const [long, setlong] = useState(null);
    const [address, setaddress] = useState('');
    const [mysocket, setmysocket] = useState(null);
    const [permission, setpermission] = useState('');
    const [fingerprintAttempts, setFingerprintAttempts] = useState(0);

    // Detect Safari browser
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    console.log("Browser detection - Safari:", isSafari);

    // Generate a fallback fingerprint for Safari
    const generateFallbackFingerprint = () => {
        const randomPart = Math.random().toString(36).substring(2, 15);
        const timePart = Date.now().toString(36);
        const screenPart = `${window.screen.width}x${window.screen.height}`;
        const userAgentHash = navigator.userAgent.split('').reduce((a, b) => {
            a = ((a << 5) - a) + b.charCodeAt(0);
            return a & a;
        }, 0);
        
        return `FB-${randomPart}-${timePart}-${screenPart}-${Math.abs(userAgentHash)}`;
    };

    useEffect(() => {
        // Try the primary fingerprinting method first
        const fpPromise = import('https://fpjscdn.net/v3/BwcpDekjRdgI3jp0m9EM')
            .then(FingerprintJS => FingerprintJS.load());

        fpPromise
            .then(fp => fp.get())
            .then(result => {
                console.log("Primary fingerprint result:", result.visitorId);
                if (result?.visitorId) {
                    setmyFingerPrint(result.visitorId);
                    localStorage.setItem('trillboard_fingerprint', result.visitorId);
                } else {
                    throw new Error("No visitorId returned");
                }
            })
            .catch(err => {
                console.log("Primary fingerprint error:", err);
                // Try secondary fingerprinting method
                getCurrentBrowserFingerPrint()
                    .then(fingerprint => {
                        console.log("Secondary fingerprint result:", fingerprint);
                        if (fingerprint) {
                            setmyFingerPrint(fingerprint);
                            localStorage.setItem('trillboard_fingerprint', fingerprint);
                        } else {
                            throw new Error("Secondary fingerprinting failed");
                        }
                    })
                    .catch(secondaryErr => {
                        console.log("Secondary fingerprint error:", secondaryErr);
                        
                        // Check if we have a stored fingerprint
                        const storedFingerprint = localStorage.getItem('trillboard_fingerprint');
                        if (storedFingerprint) {
                            console.log("Using stored fingerprint:", storedFingerprint);
                            setmyFingerPrint(storedFingerprint);
                        } else {
                            // Use fallback method for Safari
                            const fallbackFingerprint = generateFallbackFingerprint();
                            console.log("Using fallback fingerprint:", fallbackFingerprint);
                            setmyFingerPrint(fallbackFingerprint);
                            localStorage.setItem('trillboard_fingerprint', fallbackFingerprint);
                        }
                    });
            });
    }, []);
    
    useEffect(() => {
        console.log("myfingerPrint", myFingerPrint);
        if (myFingerPrint) {
            console.log("Fingerprint set successfully:", myFingerPrint);
        }
    }, [myFingerPrint]);

    useEffect(() => {
        const checkLocationPermission = async () => {
            try {
                const permissionResult = await navigator.permissions.query({
                    name: 'geolocation'
                });

                if (permissionResult.state === 'granted') {
                } else if (permissionResult.state === 'prompt') {
                } else {
                    setpermission('denied');
                }
            } catch (error) {
                console.error('Error checking location permission:', error);
            }
        };

        checkLocationPermission();
    }, []);

    const fetchAddress = async (lat, long) => {
        console.log("lat", lat);
        console.log("long", long);
        const apiKey = 'AIzaSyCImDhBaccTMO68L8dBLEsXLeb08LhqPTA';
        const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${apiKey}`;
        fetch(geocodingApiUrl)
            .then((response) => response.json())
            .then((data) => {
                if (data.results && data.results.length > 0) {
                    setaddress(data.results[0].formatted_address);
                    console.log("address data", data.results[0].formatted_address);
                    setlocationAllowed(true);
                } else {
                    console.log("address data length less", data);
                }
            })
            .catch((error) => {
                console.error('Error fetching address:', error);
            });
    };

    const success = (pos) => {
        console.log(pos);
        const { latitude, longitude } = pos.coords;
        setlat(latitude);
        setlong(longitude);
        fetchAddress(latitude, longitude);
    };

    const errors = (err) => {
        setlocationAllowed(false);
        console.log("err", err);
    };

    const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 1000000,
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, errors, options);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);

    const getScreenDiagonalPixels = (widthPixels, heightPixels) => {
        return Math.sqrt(Math.pow(widthPixels, 2) + Math.pow(heightPixels, 2));
    };

    const diagonalSizeInInches = 15.6; // Example diagonal size in inches

    const dpi = window.devicePixelRatio * 96;


    function getDeviceType() {
        const width = window.screen.width;
        const height = window.screen.height;
        const aspectRatio = width / height;
    
        if (width >= 1920 && height >= 1080) {
            if (aspectRatio > 1.7) {
                return width > 3000 ? "tv-4k" : "tv-1080p"; // 4K TVs usually have width > 3000px
            }
        } else if (width >= 1366 && width < 1920) {
            return "laptop"; // Typical laptop resolutions
        }
        return "custom"; // Fallback for unknown devices
    }


    useEffect(() => {
        if (((locationAllowed === true) || (locationAllowed === false)) && myFingerPrint) {
            if (locationAllowed) {
                console.log("Location allowed: true");
            }
            if (!locationAllowed) {
                console.log("Location allowed: false");
            }
            setloader(true);
            const getdata = async () => {
                try {
                    console.log("Checking screen with fingerprint:", myFingerPrint);
                    const response = await axios.get(`${process.env.REACT_APP_APP_URL}v2/earner/check-screen/${myFingerPrint}`, {
                        headers: {
                            "Content-Type": 'application/json'
                        }
                    });
                    
                    console.log("Check screen response:", response.data);
                    
                    if (response?.data?.status === false) {
                        console.log("Creating new socket connection with fingerprint:", myFingerPrint);
                        const socket = io(`https://chat.trillboards.com?fingerprint=${myFingerPrint}`, {
                            transports: ['websocket', 'polling'],
                            reconnectionAttempts: 5,
                            reconnectionDelay: 1000
                        });
                        setmysocket(socket);

                        socket.on('connect', () => {
                            console.log('WebSocket connected with ID:', socket.id);

                            const addScreen = async () => {
                                const widthPixels = window.screen.width;
                                const heightPixels = window.screen.height;
                                const dpi = window.devicePixelRatio * 96; // Approximate DPI
                              
                                const widthInches = widthPixels / dpi;
                                const heightInches = heightPixels / dpi;
                                
                                console.log("Attaching screen with dimensions:", {
                                    width: widthInches,
                                    height: heightInches,
                                    fingerprint: myFingerPrint,
                                    socketId: socket.id
                                });
                                
                                const formdata = new FormData();
                                formdata.append('dimension_width', widthInches);
                                formdata.append('dimension_height', heightInches);
                                formdata.append('fingerPrint', myFingerPrint);
                                formdata.append('ppi', dpi);
                                formdata.append('socket_id', socket.id);
                                formdata.append('location', address);
                                formdata.append('lat', Number(lat) || 0);
                                formdata.append('long', Number(long) || 0);

                                try {
                                    const attachResponse = await axios.post(
                                        `${process.env.REACT_APP_APP_URL}v2/earner/attach-screen`, 
                                        formdata, 
                                        {
                                            headers: {
                                                "Content-Type": 'application/json'
                                            }
                                        }
                                    );
                                    
                                    console.log("Attach screen response:", attachResponse.data);
                                    
                                    if (attachResponse?.data?.status === true) {
                                        setmyId(attachResponse?.data?.data?._id);
                                        console.log("Screen attached successfully with ID:", attachResponse?.data?.data?._id);
                                        setloader(false);
                                    }
                                } catch (err) {
                                    console.log("Error attaching screen:", err);
                                    setloader(false);
                                }
                            };

                            addScreen();
                        });

                        socket.on('connect_error', (error) => {
                            console.log('Socket connection error:', error);
                        });

                        socket.on('disconnect', () => {
                            console.log('WebSocket disconnected');
                        });

                        socket.on('receiveMessage', (data) => {
                            console.log('Received message from server:', data);
                        });
                        
                        socket.on('chatmessage', (data) => {
                            console.log('Received chat message from server:', data);
                        });

                        socket.on('privateMessage', (data) => {
                            console.log("Received private message:", data);
                            if (JSON.parse(data)?.fetchAdd === true) {
                                window.location.reload();
                            }
                        });
                    }
                    else if (response?.data?.status === true) {
                        console.log("Screen already exists with ID:", response?.data?.data?._id);
                        setmyId(response?.data?.data?._id);
                        setconnected(true);
                        setloader(false);
                    }
                } catch (err) {
                    console.log("Error checking screen:", err);
                    setloader(false);
                }
            };
            
            getdata();
            console.log("Screen dimensions:", window.innerHeight, window.innerWidth);
        }
    }, [locationAllowed, permission, myFingerPrint]);

    return (
        <>
            {!connected ?
                <>
                    {(loader || locationAllowed === null) ? <div id="loader-overlay">
                        <div className="loader"></div>
                    </div>
                        :
                        <>
                            <section className="trillboard_wrap">
                                <div className="blue_boxwrap">
                                    <div className="trillboard_image">
                                        <img src={trillBoardLogo} alt="" />
                                    </div>
                                </div>
                            </section>
                            <section className="trillboard_scanboard sucess_connect" >
                                <div className="scanorcode_wrap">
                                    <QRCode
                                        size={200}
                                        style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                                        value={`${myFingerPrint}?location=${locationAllowed}`}
                                        logoImage={trillBoardLogo}
                                        logoPadding={1}
                                        logoHeight={40}
                                        logoWidth={40}
                                    />
                                    <h2>Scan to Connect Screen</h2>
                                </div>
                                <div className="trillboard_videeo_list">
                                    <div className="trillboard_list">
                                        <p>1. Open Trill boards on your Phone</p>
                                        <p>2. Tap menu on right of Homepage </p>
                                        <p>3. Capture the QR code from the Screen</p>
                                        <p>4. Advertise</p>
                                    </div>
                                    <div className="trillboard_video_image">
                                        <img src={videoImage} alt="" />
                                    </div>
                                </div>
                            </section>
                        </>}
                </>
                : <ScreenConnected myFingerPrint={myFingerPrint} myId={myId} socket={mysocket} />}
        </>
    );
}

export default QRcode;
